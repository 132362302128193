
var COS = require('cos-js-sdk-v5');
export default new (class {
    constructor() {
    }

    _cos(obj) {
        return new COS({
            getAuthorization: function (options, callback) {
                callback({
                    TmpSecretId: obj.tmpSecretId,
                    TmpSecretKey: obj.tmpSecretKey,
                    SecurityToken: obj.sessionToken,
                    // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                    StartTime: obj.startTime, // 时间戳，单位秒，如：1580000000
                    ExpiredTime: obj.expiredTime, // 时间戳，单位秒，如：1580000000
                });
            }
        });
    }

    /**
     * 文件直传
     * @param {Object} obj Bucket、Region...，接口返回
     * @param file
     * @param callback
     * @returns err || data
     */
    putObject(obj, file, callback) {
        return new Promise(() => {
            let percent = 0;
            this._cos(obj).putObject(
                {
                    Bucket: obj.bucket, /* 必须 */
                    Region: obj.region,     /* 存储桶所在地域，必须字段 */
                    Key: `/TMP/${file.name}`,              /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: file.file, // 上传文件对象
                    onProgress: function(progressData) {
                        percent = progressData.percent;
                    }
                },
                (err, data) => {
                    callback(err, data,percent)
                }
            );
        });
    }

    /**
     * 删除对象
     * @param obj
     * @param file
     * @param callback
     * @returns {Promise<unknown>}
     */
    delObject(obj,file,callback) {
        return new Promise(() => {
            this._cos(obj).deleteObject(
                {
                    Bucket: obj.bucket, /* 必须 */
                    Region: obj.region,     /* 存储桶所在地域，必须字段 */
                    Key: file,              /* 必须 */
                },
                (err, data) => {
                    callback(err, data)
                }
            );
        })
    }

})


