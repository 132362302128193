import request from "@/api/request";


/**
 * 获取腾讯云临时秘钥
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getTempSecretKey(params) {
    return await request.post("/comm/getTempSecretKey", params)
}

/**
 * 标签获取
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepFeeTypeList(params) {
    return await request.post("/keep/getKeepFeeTypeList", params)
}


/**
 * 标签获取
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepContractList(params) {
    return await request.post("/contract/getKeepContractList", params)
}

